body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html, body, #root {
  height: 100%;
}

/* unvisited link */
a:link {
  color: #c885e4;
}

/* visited link */
a:visited {
  color: #d1a371;
}

/* mouse over link */
a:hover {
  color: #85cbf5;
}

/* selected link */
a:active {
  color: #85cbf5;
}
